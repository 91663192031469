import React, { useState } from 'react';

import { PageHeader, VideoThumbnail, Button } from '../components';

import '../styles/tutorial-video-hub.css';

const VideoCard = ({
    title,
    description,
    thumbnail,
    video,
    pdf
}, i) => (
    <div key={ `${title}-${i}` } className='p-4 shadow-lg transition-all hover:bg-light'>
        <div className='float-left w-1/2 pr-2'>
            <VideoThumbnail
                title={ title }
                thumbnail={ thumbnail }
                video={ video }
                videoLink={ `/tutorial-videos/${title.toLowerCase().replace(/\s/g, '-')}` }
                size='small'
            />
        </div>
        <div className='flex flex-col float-right w-1/2 h-full pl-2'>
            <div className='mb-2 text-xl lg:text-2xl text-secondary font-medium'>{ title }</div>
            <div className='text-base lg:text-lg text-primary'>{ description }</div>
            {pdf && <Button
                text='View PDF'
                action={ () => window.open(pdf) }
                className='mt-auto bg-secondary text-white'
            />}
        </div>
    </div>
);


const TutorialVideoHubPageTemplate = ({
    headerTitle,
    headerImage,
    heroTitle,
    videos
}) => {
    const [ tab, setTab ] = useState('dashboard-page');

    let categories = {  };

    for (const video of videos) {
        const { category } = video;
        if (categories[category]) categories[category].push(video);
        else categories[category] = [ video ];
    }

    return (
        <div className='w-full lg:pb-40'>
            <PageHeader
                text={ headerTitle }
                image={ headerImage }
            />
            <div className='w-full px-4 lg:px-0 lg:w-3/5 mx-auto my-4 lg:my-16 text-black text-2xl lg:text-4xl'>{ heroTitle }</div>
            <div className='w-full'>
                <div className='w-full px-4 xl:px-0 xl:w-3/4 mx-auto'>
                    <div className={ `w-full grid grid-cols-1 sm:grid-cols-${Object.keys(categories).length} shadow-lg` } role='menu'>
                        { tabOrder.map(({ name, label }, i) => categories[name] ? (
                            <div
                                key={ i }
                                className={ `tab-title ${tab === name ? 'active' : '' }` }
                                onClick={ () => setTab(name) }
                                onKeyPress={ e => setTab(name) }
                                role='menuitemradio'
                                aria-label={ `Select page menu item ${name}` }
                                aria-checked={ tab === name }
                                tabIndex={ 0 }
                            >
                                { label }
                            </div>
                        ) : null) }
                    </div>
                    <div className='w-full py-4 lg:py-8'>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                            { categories[tab].map(VideoCard) }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const tabOrder = [
    {
        name: 'dashboard-page',
        label: "Page Functions"
    }, {
        name: 'dashboard-feature',
        label: "Features"
    }, {
        name: 'understanding-esg',
        label: "Understanding ESG"
    }, {
        name: 'other',
        label: "Other"
    }
];

export default TutorialVideoHubPageTemplate;