import React from 'react';
import { Link } from 'gatsby';

import { PageHeader, HTML, Button } from '../components';

import '../styles/insights.css';

const ContentPreview = nContent => ({
    fileAbsolutePath,
    frontmatter
}, i) => {
    const { title, date, paragraph1 } = frontmatter;
    const pathParts = fileAbsolutePath.split('/');

    return (
        <Link key={ i } to={ `/insights/${pathParts[pathParts.length - 1].split('.')[0]}` }>
            <div className={ `w-full ${i < nContent - 1 ? 'mb-4' : ''} p-2 md:p-4 border border-gray-500 transition-all hover:bg-light` }>
                <div className='mb-2 text-2xl lg:text-4xl text-primary'>{ title }</div>
                <div className='mb-2 text-sm text-gray-500'>{ (new Date(Date.parse(date))).toLocaleString().split(', ')[0] }</div>
                { paragraph1 && <HTML className='clipped-paragraph mb-3 w-full text-justify md:text-lg text-primary' html={ paragraph1 }/> }
                <div className='flex flex-row-reverse'>
                    <Button text="Read more"/>
                </div>
            </div>
        </Link>
    );
};

const InsightsPageTemplate = ({
    headerImage,
    headerTitle,
    content
}) => (
    <div className='w-full pb-4 lg:pb-40'>
        <PageHeader
            text={ headerTitle }
            image={ headerImage }
            className='mb-4 lg:mb-8'
        />
        <div className='w-full px-4 md:px-0 md:w-4/5 lg:w-3/5 mx-auto'>
            { content?.length > 0 ? content.map(ContentPreview(content.length)) : (
                <div className='w-full text-2xl text-gray-500 text-center'>
                    No content yet.  Check back soon!
                </div>
            ) }
        </div>
    </div>
);

export default InsightsPageTemplate;