import React, { useState } from "react";
import { navigate } from "gatsby";
import {
  FaAngleUp,
  FaAngleDown,
  FaRegPlayCircle,
  FaLink,
} from "react-icons/fa";

import {
  PageHeader,
  HTML,
  Button,
  DemoButton,
  VideoThumbnail,
  Icon,
} from "../components";
// PrivateEquityFeature

const PrivateEquityFeature = (expanded, setExpanded) => {
  return ({ icon, title, body }, i) => (
    <div key={i}>
      <div className="p-4 lg:px-12 lg:py-8 bg-primary rounded-2xl shadow-xl flex flex-row transition-all">
        <Icon
          icon={icon}
          className="mt-4 mr-4 lg:mr-8"
          iconClassName="text-4xl lg:text-5xl text-highlight"
        />
        <div className="flex flex-col justify-between">
          {expanded.indexOf(i) !== -1 ? (
            <HTML
              className="text-lg lg:text-xl text-justify text-white font-light"
              html={body}
            />
          ) : (
            <div className="text-2xl lg:text-3xl text-white font-light">
              {title}
            </div>
          )}
          <div
            className="flex flex-row mt-4 lg:mt-8 text-base lg:text-lg text-highlight cursor-pointer hover:underline"
            onClick={() => {
              if (expanded.indexOf(i) !== -1) {
                expanded.splice(expanded.indexOf(i), 1);
                let arr = [...expanded];
                setExpanded(arr);
              } else {
                expanded.push(i);
                let arr = [...expanded];
                setExpanded(arr);
              }
            }}
            onKeyPress={(e) => {
              e.key === " " && expanded.push(i);
              let arr = [...expanded];
              setExpanded(arr);
            }}
            role="button"
            aria-label={expanded.indexOf(i) !== -1 ? "Show less" : "Show more"}
            tabIndex={0}
          >
            <div>{expanded.indexOf(i) !== -1 ? "Show less" : "Show more"}</div>
            <Icon className="flex items-center ml-2">
              {expanded.indexOf(i) !== -1 ? <FaAngleUp /> : <FaAngleDown />}
            </Icon>
          </div>
        </div>
      </div>
    </div>
  );
};

const PrivateEquityVideo =
  (nVideos) =>
  ({ title, thumbnail, video }, i) =>
    (
      <div
        key={i}
        className={`md:w-5/12 flex flex-col justify-between${
          i === nVideos - 1 ? "" : ""
        }`}
      >
        <div className="w-full mb-4 lg:mb-12 text-center text-xl lg:text-3xl text-primary">
          {title}
        </div>
        <VideoThumbnail
          title={title}
          thumbnail={thumbnail}
          video={video}
          videoLink={`/videos/${title.toLowerCase().replace(/\s/g, "-")}`}
          className="shadow-lg"
          playButtonColour="primary"
          size="medium"
        />
      </div>
    );

const PrivateEquityPageTemplate = ({
  headerImage,
  headerTitle,
  headerBottomButton,
  headerBottomButtonLink,
  heroTitle,
  features,
  partnerLogo,
  partnerTitle,
  partnerBody,
  partnerButton,
  partnerButtonLink,
  videosTitle,
  videos,
}) => {
  const [expanded, setExpanded] = useState([]);
  return (
    <section>
      <div className="w-full">
        <PageHeader text={headerTitle} image={headerImage}>
          <div className="absolute bottom-0 lg:bottom-5 xl:bottom-10 right-3 lg:right-6 xl:right-12">
            <a
              href={headerBottomButtonLink}
              aria-label="Integrum ESG dashboard link"
              target="_blank"
              rel="noreferrer"
            >
              <Button
                text={headerBottomButton}
              />
            </a>
          </div>
        </PageHeader>
        <div className="w-full pt-4 lg:pt-16">
          <div className="w-full px-4 lg:px-0 lg:w-4/5 mx-auto mb-8 lg:mb-20 text-center text-2xl lg:text-4xl text-primary">
            {heroTitle}
          </div>

          <div className="w-full px-4 lg:px-0 lg:w-4/5 3xl:w-3/5 mx-auto grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-x-16 lg:gap-y-8">
            {features.map(PrivateEquityFeature(expanded, setExpanded))}
          </div>
        </div>
      </div>
      <div className="w-full py-12   mt-12 lg:py-24 lg:mt-24 3xl:py-32 3xl:mt-32 bg-light">
        <div className="flex flex-col lg:flex-row px-4 lg:px-0  lg:w-4/5 mx-auto items-start md:items-center">
          <div className="mb-16 lg:mb-0">
            <img src={partnerLogo.sourceURL} />
          </div>
          <div className="md:w-2/3 lg:pl-20">
            <div className="w-full  lg:px-0 mx-auto mb-4 lg:mb-8 text-2xl lg:text-4xl text-primary font-bold">
              {partnerTitle}
            </div>
            <p className="markdown-html w-full mb-4 lg:mb-8  text-justify text-lg lg:text-2xl text-primary">
              {partnerBody}
            </p>
            <div className="w-full flex -ml-4">
              <Button
                text={partnerButton}
                action={() => navigate(partnerButtonLink)}
                style={{ background: "#3070B8" }}
              />
              {/* #3070B8 */}
            </div>
          </div>
        </div>
      </div>

      <div className="w-full py-12 lg:py-24 3xl:py-32">
        {/* <div className="flex ">
          <div className="w-full mx-auto pl-40 3xl:pl-96">
            <VideoThumbnail
              thumbnail={videosThumbnail.sourceURL}
              video={videosLink}
              videoLink={`/videos/${videosTitle
                .toLowerCase()
                .replace(/\s/g, "-")}`}
              className="shadow-lg"
              playButtonColour="primary"
              size="medium"
            />
          </div>
          <div className="w-full flex flex-col justify-center h-full">
            <div className="w-full  lg:w-4/5 ml-10 mb-4 lg:mb-8 text-2xl lg:text-4xl text-primary">
              {videosTitle}
            </div>
            <p className="markdown-html w-3/4  3xl:w-2/4 ml-10  mb-4 lg:mb-16  text-justify text-lg lg:text-2xl text-primary">
              {videosBody}
            </p>
          </div>
        </div> */}
        <div className="w-full px-4 lg:px-0 lg:w-4/5 mx-auto mb-8 lg:mb-16 text-center text-2xl lg:text-4xl text-primary">
          {videosTitle}
        </div>
        <div className="w-full px-4 lg:px-0 xl:w-3/5 md:w-4/5 flex flex-col md:flex-row md:flex-wrap justify-center mx-auto mb-12 lg:mb-24 gap-8 lg:gap-20">
          {videos.map(PrivateEquityVideo(videos.length))}
        </div>
        <div className="w-full flex justify-center">
          <DemoButton />
        </div>
      </div>
    </section>
  );
};

export default PrivateEquityPageTemplate;
