import React from 'react';
import { FaLinkedinIn } from 'react-icons/fa';

import { PageHeader, Image, Icon } from '../components';

import '../styles/meet-the-team.css';

const TeamMember = ({
    name,
    role,
    picture,
    blurb,
    linkedin
}, i) => (
    <div key={ i } className='team-member-profile w-full relative'>
        <div className='foreground w-full transition-all'>
            <Image className='w-full rounded-full overflow-hidden mb-4' image={ picture } aspectRatio={ 1 } size='small' alt={ `Profile picture for ${name}` }/>
            <div className='w-full text-center text-2xl font-medium'>{ name }</div>
            <div className='w-full text-center text-lg'>{ role }</div>
        </div>
        <div className='background w-full h-full overflow-auto p-4 absolute top-0 flex flex-col justify-between opacity-0 transition-all duration-300 bg-light'>
            <div className='w-full mb-4 text-justify text-base md:text-lg text-primary'>{ blurb }</div>
            { linkedin && (
                <a href={ linkedin } target='_blank' rel='noreferrer' aria-label={ `LinkedIn profile link for ${name}` }>
                    <div className='w-full flex flex-row justify-end'>
                        <Icon className='text-gray-500 text-4xl'>
                            <FaLinkedinIn/>
                        </Icon>
                    </div>
                </a>
            ) }
        </div>
    </div>
);

const MeetTheTeamPageTemplate = ({
    headerImage,
    headerTitle,
    heroTitle,
    members
}) => (
    <div className='w-full pb-10 sm:pb-40'>
        <PageHeader
            text={ headerTitle }
            image={ headerImage }
        />
        <div className='w-full lg:w-3/5 px-4 lg:px-0 mx-auto mt-4 mb-8 lg:mt-8 lg:mb-16 text-center text-primary text-2xl lg:text-3xl font-light'>{ heroTitle }</div>
        <div className='w-4/5 sm:w-full sm:px-4 md:w-4/5 md:px-0 lg:w-3/5 3xl:w-2/5 mx-auto grid grid-cols-1 sm:grid-cols-2 gap-10 lg:gap-x-20 2xl:gap-x-40 2xl:gap-y-20'>
            { members.map(TeamMember) }
        </div>
    </div>
);

export default MeetTheTeamPageTemplate;