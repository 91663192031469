import React from 'react';
import { Link } from 'gatsby';
import { BsPersonBadge } from 'react-icons/bs'

import { PageHeader, HTML, Button, Icon } from '../components';

import '../styles/careers.css';

const JobPostingPreview = ({
    fileAbsolutePath,
    frontmatter
}, i) => {
    const { title, date, jobSpec } = frontmatter;
    const pathParts = fileAbsolutePath.split('/');

    return (
        <Link key={ i } to={ `/careers/${pathParts[pathParts.length - 1].split('.')[0]}` }>
            <div className='w-full mb-4 p-2 md:p-4 flex flex-col items-center transition-all hover:bg-light'>
                <Icon className='mb-2' iconClassName='text-6xl'>
                    <BsPersonBadge/>
                </Icon>
                <div className='mb-2 text-2xl lg:text-4xl text-primary'>{ title }</div>
                <div className='mb-2 text-sm text-gray-500'>posted on { (new Date(Date.parse(date))).toLocaleString().split(', ')[0] }</div>
                <HTML className='clipped-paragraph mb-4 w-full text-center md:text-lg text-dark' html={ jobSpec }/>
                <Button text="See job spec"/>
            </div>
        </Link>
    );
};

const CareersPageTemplate = ({
    headerImage,
    headerTitle,
    heroTitle,
    careers
}) => (
    <div className='w-full pb-4 lg:pb-40'>
        <PageHeader
            text={ headerTitle }
            image={ headerImage }
            className='mb-4 lg:mb-8'
        />
        <div className='w-full lg:w-3/5 px-4 lg:px-0 mx-auto mt-4 mb-8 lg:mt-8 lg:mb-16 text-center text-primary text-2xl lg:text-3xl font-light'>{ heroTitle }</div>
        <div className='w-full px-4 md:px-0 md:w-4/5 lg:w-3/5 mx-auto'>
            { careers?.length > 0 ? careers.map(JobPostingPreview) : (
                <div className='w-full text-2xl text-gray-500 text-center'>
                    No postings yet.  Check back soon!
                </div>
            ) }
        </div>
    </div>
);

export default CareersPageTemplate;