import React, { useState, Fragment } from 'react';

import { PageHeader, Icon, Modal } from '../components';

import '../styles/metrics.css';

const SubMetric = (indexOffset, showDetail, setShowDetail) => ({
    value,
    unit,
    comment
}, i) => (
    <div key={ indexOffset + i } className='mb-2'>
        <div className='text-base lg:text-lg'>{ value } ({ unit })</div>
        <div
            className='text-sm lg:text-base underline text-gray-500 cursor-pointer hover:text-gray-800'
            onClick={ () => setShowDetail(indexOffset + i) }
            onKeyPress={ e => e.key === ' ' && setShowDetail(indexOffset + i) }
            role='button'
            aria-label="Show detail modal for submetric"
            tabIndex={ 0 }
        >
            View Detail
        </div>
        { showDetail === indexOffset + i && (
            <Modal closeModal={ () => setShowDetail(null) }>
                <div className='mb-2 text-xl text-center text-gray-500'>Integrum ESG's Performance Value:</div>
                <div className='mb-12 text-4xl text-center text-primary'>{ value } ({ unit })</div>
                <div className='text-lg'>
                    <div className='mb-2 text-gray-500'>Integrum ESG's comment:</div>
                    <div className='text-justify'>{ comment }</div>
                </div>
            </Modal>
        ) }
    </div>
);

const MetricRow = (currentPillar, showDetail, setShowDetail) => ({
    name,
    submetrics
}, i) => (
    <Fragment key={ currentPillar * 100 + i }>
        <div className='text-lg lg:text-2xl'>{ name }</div>
        <div className='flex flex-col pl-4 sm:pl-0'>
            { submetrics.map(SubMetric((currentPillar * 100 + i) * 100, showDetail, setShowDetail)) }
        </div>
    </Fragment>
);

const MetricsPageTemplate = ({
    headerImage,
    headerTitle,
    heroTitle,
    heroSubtitle,
    pillars
}) => {
    const [ currentPillar, setCurrentPillar ] = useState(0);
    const [ showDetail, setShowDetail ] = useState(null);
    return (
        <div className='w-full pb-40'>
            <PageHeader
                text={ headerTitle }
                image={ headerImage }
            />
            <div className='w-full px-4 lg:px-0 lg:w-3/5 mx-auto my-4 lg:my-8 text-center text-primary text-3xl lg:text-5xl font-light'>{ heroTitle }</div>
            <div className='w-full px-4 lg:px-0 lg:w-3/5 mx-auto mb-8 lg:mb-16 text-center text-primary text-lg lg:text-2xl font-light'>{ heroSubtitle }</div>
            <div className='w-full px-4 lg:px-0 lg:w-4/5 3xl:w-3/5 mx-auto flex flex-col sm:flex-row'>
                <div className='pb-2 sm:pr-2 lg:pr-4 border-b sm:border-b-0 sm:border-r border-primary' role='menu'>
                    { pillars.map(({ name, icon }, i) => (
                        <div
                            key={ i }
                            className='pillar-menu-item'
                            onClick={ () => setCurrentPillar(i) }
                            onKeyPress={ e => e.key === ' ' && setCurrentPillar(i) }
                            role='menuitemradio'
                            aria-label={ `Select page menu item ${name}` }
                            aria-checked={ currentPillar === i }
                            tabIndex={ 0 }
                        >
                            <Icon icon={ icon } className='flex items-center' iconClassName='text-2xl lg:text-4xl text-secondary'/>
                            <div className={ `pillar-menu-item-text ml-2 lg:ml-4 text-xl lg:text-3xl text-${currentPillar === i ? 'secondary' : 'primary'}` }>{ name }</div>
                        </div>
                    )) }
                </div>
                <div className='w-full pl-2 lg:pl-8 py-2 lg:py-4 grid grid-cols-1 sm:grid-cols-2 gap-2 lg:gap-x-4 lg:gap-y-8'>
                    { pillars[currentPillar].metrics.map(MetricRow(currentPillar, showDetail, setShowDetail)) }
                </div>
            </div>
        </div>
    );
};

export default MetricsPageTemplate;