import React from "react";
import { navigate } from "gatsby";

import {
  Button,
  HTML,
  PageHeader,
  Image,
  TestimonialSlider,
} from "../components";

import "../styles/our-story.css";
import ClientLogoSlider from "../components/ClientLogoSlider";

const OurStoryPageTemplate = ({
  headerImage,
  testimonials,
  headerTitle,
  testimonialTitle,
  clientLogo,
  heroTitle,
  heroBody,
  continueTitle,
  continueBody,
  conclusionImage,
  conclusionTitle,
  conclusionBody,
}) => (
  <section>
    <div>
      <PageHeader text={headerTitle} image={headerImage} />
      <div className="w-full lg:w-3/5 mx-auto text-center text-3xl lg:text-5xl text-primary mb-12 mt-16">
        {testimonialTitle}
      </div>
      <TestimonialSlider items={testimonials} />
      <ClientLogoSlider items={clientLogo} />

      <div className="w-2/4 h-1 bg-light mx-auto mt-16"></div>

      <div className="full-page-container-body realtive p-4 lg:px-0 lg:py-8 flex flex-col justify-around my-16">
        <div className="w-full lg:w-3/5 mx-auto text-center text-3xl lg:text-5xl text-primary">
          {heroTitle}
        </div>
        <HTML
          className="w-full md:w-4/5 lg:w-3/5 mx-auto my-16 text-justify text-lg lg:text-2xl text-primary"
          html={heroBody}
        />
        <div className="w-full flex justify-center">
          <Button
            text="Find out more about the Team"
            action={() => navigate("/meet-the-team")}
          />
        </div>
      </div>
    </div>

    <div className="full-page-container relative text-white bg-gradient-to-br from-primary via-gray-900 to-primary">
      <div className="w-full h-full px-4 py-8 lg:px-0 3xl:py-16 flex flex-col justify-around">
        <div>
          <div className="w-full md:w-4/5 lg:w-3/5 mx-auto mb-4 md:mb-6 lg:mb-16 text-center text-3xl md:text-4xl lg:text-5xl z-20">
            {continueTitle}
          </div>
          <HTML
            className="w-full md:w-4/5 lg:w-3/5 mx-auto text-justify text-lg lg:text-2xl z-20"
            html={continueBody}
          />
        </div>
      </div>
      <div className="our-story-shape-1 w-full h-full absolute top-0 z-10 bg-secondary" />
      <div className="our-story-shape-2 w-full h-full absolute top-0 z-10 bg-secondary" />
    </div>
    <div className="full-page-container text-primary bg-light">
      <div className="w-full h-header-1/5 lg:h-header-2/5 overflow-hidden">
        <Image
          className="w-full"
          image={conclusionImage}
          alt="Header image for conclusion section"
        />
      </div>
      <div className="w-full h-4/5 lg:h-3/5 px-4 py-8 lg:px-0 3xl:py-16 flex flex-col justify-around">
        <div className="w-full lg:w-4/5 mx-auto mb-4 text-center text-3xl lg:text-4xl 2xl:text-5xl">
          {conclusionTitle}
        </div>
        <HTML
          className="w-full md:w-4/5 mx-auto text-justify text-lg lg:text-xl 2xl:text-2xl"
          html={conclusionBody}
        />
      </div>
    </div>
  </section>
);

export default OurStoryPageTemplate;
