import React from "react";
import { navigate } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import { Image, Button, DemoButton, HTML, PageHeader } from "../components";

import "../styles/index.css";

const IndexFeatures = ({ title, body }, i) => (
  <div key={i} className="w-full mb-4 lg:mb-0">
    <div className="w-full text-center font-medium text-4xl">{title}</div>
    <HTML className="w-full mt-4 text-justify text-lg" html={body} />
  </div>
);

const ClientLogo = ({ logo }, i) => (
  <div key={i} className="mb-4 w-20 mx-auto text-center lg:mb-0">
    <img
      className="w-9/12 h-full  mx-auto mt-1 object-contain	filter grayscale"
      src={logo.sourceURL}
    />
  </div>
);

const IndexPageTemplate = ({
  headerImage,
  heroTitle,
  heroText,
  clientLogoTitle,
  clientLogo,
  heroVideo,
  featuresBody,
  featuresVideo,
  features,
  featureButtonOne,
  featureButtonOneLink,
  featureButtonTwo,
  featureButtonTwoLink,
  globeTitle,
  globeBody,
  globeVideo,
}) => (
  <section>
    <div className=" border-b border-primary">
      <PageHeader image={headerImage} imageClassName="w-4/6" />
      <div className="full-page-container-body py-8 lg:py-0">
        <div className="flex flex-col justify-around lg:grid lg:grid-cols-5 h-full">
          <div className="px-8 lg:px-0 lg:col-span-2 mb-8 sm:mb-12 lg:mb-0">
            <div className="flex flex-col lg:pt-5 lg:pl-10 xl:pt-10 3xl:pt-20 3xl:pl-20 lg:pr-10 3xl:pr-20 mb-16">
              <div className="text-center lg:text-left text-5xl md:text-6xl text-primary mb-5">
                {heroTitle}
              </div>
              <div className="text-center lg:text-justify text-lg text-dark lg:w-5/6 3xl:w-4/6 mb-8">
                {heroText}
              </div>
              <div className="w-4/4 h-1 bg-light relative -top-3"></div>
              <div className="text-center lg:text-justify text-lg text-dark lg:w-5/6 3xl:w-4/6 mb-8 font-bold">
                {clientLogoTitle}
              </div>
              <div className="flex lg:hidden 2xl:flex flex-wrap md:gap-4  mb-8 mx-auto 2xl:mx-0 max-w-1/5w-80 md:w-2w-80">
                {clientLogo.map(ClientLogo)}
              </div>

              <div className="flex justify-center lg:justify-start">
                <DemoButton />
              </div>
            </div>
          </div>
          <div className="px-4 lg:col-span-3 lg:pl-0 lg:pr-20">
            <div className="relative w-full md:w-5/6 lg:w-full mx-auto lg:-top-1/4 lg:z-20 lg:-mb-full">
              <StaticImage
                src="../images/monitor.png"
                alt="Computer monitor frame"
              />
              <img src="../images/monitor.png" alt="" />

              <div
                className="absolute left-0 top-0 right-0 bottom-0"
                style={{ maxWidth: "1320px" }}
              >
                <div
                  className="absolute bg-black"
                  style={{
                    top: "6%",
                    left: "12%",
                    right: "12%",
                    bottom: "9%",
                  }}
                >
                  <Image
                    image={heroVideo}
                    alt="Demonstration of Integrum ESG's 'glass box' feature"
                    size="medium"
                  />
                </div>
              </div>
            </div>

            <div
              className="lg:flex 2xl:hidden flex-wrap md:gap-4 mb-8 mx-auto max-w-1/5w-80 md:w-2w-80"
              style={{ marginTop: "88%" }}
            >
              {clientLogo.map(ClientLogo)}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="w-full bg-light px-4 pt-8 pb-4 lg:px-0 lg:py-16">
      <HTML
        className="w-full lg:w-3/5 mx-auto mb-6 text-center text-primary text-3xl lg:text-5xl leading-normal"
        html={featuresBody}
      />
      <Image
        image={featuresVideo}
        className="w-full md:w-5/6 lg:w-1/2 mx-auto border-4 lg:border-8 border-primary"
        alt="Demonstration of Integrum ESG's customisation feature"
        size="medium"
      />
      <div
        className={`w-4/5 mx-auto mt-8 text-primary lg:grid lg:grid-cols-${features.length} gap-x-20`}
      >
        {features.map(IndexFeatures)}
      </div>
      <div className="mt-4 lg:mt-8 flex justify-center text-center">
        <Button
          text={featureButtonOne}
          action={() => navigate(featureButtonOneLink)}
        />
        <Button
          className="bg-primary text-white"
          text={featureButtonTwo}
          action={() => navigate(featureButtonTwoLink)}
        />
      </div>
    </div>
    <div className="w-full h-24 lg:h-60 bg-light">
      <div className="index-green-shape w-full h-full bg-secondary" />
      <div className="index-blue-cutout w-full h-full relative -top-full mt-px z-10 bg-primary" />
    </div>
    <div className="w-full bg-black text-white">
      <div className="w-full pt-8 lg:pt-16 bg-gradient-to-b from-primary to-black">
        <div className="w-full lg:w-4/5 px-4 lg:px-0 mx-auto">
          <div className="text-center text-4xl lg:text-5xl mb-6 lg:mb-12">
            {globeTitle}
          </div>
          <HTML
            className="text-justify text-lg lg:text-2xl font-light"
            html={globeBody}
          />
        </div>
      </div>
      <div className="index-globe-container relative w-full lg:w-4/5 3xl:w-3/5 mx-auto">
        <Image
          image={globeVideo}
          alt="Spinning globe background video"
          size="medium"
        />
      </div>
      <div className="w-full h-16 lg:h-40">
        <div className="index-bottom-cutout w-full h-full bg-white" />
      </div>
    </div>
  </section>
);

export default IndexPageTemplate;
