import React from 'react';

import { PageHeader, HTML, Image } from '../components';

const AssessmentFrameworkFramework = ({
    logo,
    body
}, i) => (
    <div key={ i } className='flex flex-col p-4 md:p-8 lg:p-16 rounded-xl shadow-xl'>
        <Image className='w-full h-32 mb-6' objectFit='contain' background="white" size='medium' image={ logo } alt={ `Logo for assessment framework ${i + 1}` }/>
        <HTML className='text-justify text-lg font-light' html={ body }/>
    </div>
);

const AssessmentFrameworkPageTemplate = ({
    headerImage,
    headerTitle,
    heroBody,
    frameworks
}) => (
    <section>
        <div className='w-full shadow-xl'>
            <PageHeader
                text={ headerTitle }
                image={ headerImage }
                cutoutColour='bg-primary'
            />
            <div className='w-full py-8 lg:pt-16 lg:pb-32 bg-gradient-to-b from-primary to-gray-900'>
                <HTML className='w-full px-8 lg:px-0 lg:w-3/5 mx-auto text-xl lg:text-3xl text-justify font-light text-white' html={ heroBody }/>
            </div>
        </div>
        <div className='w-full py-4 lg:py-16'>
            <div className='w-full px-4 lg:px-0 lg:w-4/5 3xl:w-3/5 mx-auto grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-16'>
                { frameworks.map(AssessmentFrameworkFramework) }
            </div>
        </div>
    </section>
);

export default AssessmentFrameworkPageTemplate;