import React from "react";
import "slick-carousel/slick/slick.css";
import Slider from "react-slick";
import { Button, HTML, PageHeader, Image } from "../components";

import { FaAngleRight, FaAngleLeft } from "react-icons/fa";

const ClientLogoFeatures = ({ logo }, i) => (
  <div key={i}>
    <div className="w-11/12 mx-4 mb-4 lg:mb-0 gap-4 flex items-center p-4 lg:p-10 filter">
      <img className="w-20 h-20 object-contain  mt-1" src={logo.sourceURL} />
    </div>
  </div>
);

const ClientLogoSlider = ({ items }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 400,
    arrows: false,
    slidesToShow: 8,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 8,
        },
      },
      {
        breakpoint: 999,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 5,
        },
      },
    ],
  };

  return (
    <div className="w-11/12 mx-auto">
      <Slider {...settings}>{items.map(ClientLogoFeatures)}</Slider>
    </div>
  );
};

export default ClientLogoSlider;
