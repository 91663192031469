import React from 'react';

import { PageHeader, HTML, DemoButton } from '../components';

const HowItWorksPageTemplate = ({
    headerImage,
    headerTitle,
    heroTitle,
    heroBody
}) => (
    <section>
        <div className='full-page-container'>
            <PageHeader
                text={ headerTitle }
                image={ headerImage }
            />
            <div className='full-page-container-body w-full p-4 lg:px-0 lg:py-8 3xl:py-16 flex flex-col justify-around text-primary'>
                <div className='w-full lg:w-4/5 3xl:w-3/5 mx-auto text-center text-3xl lg:text-4xl 3xl:text-5xl'>{ heroTitle }</div>
                <HTML className='w-full lg:w-4/5 3xl:w-3/5 mx-auto my-4 text-justify text-lg' html={ heroBody }/>
                <div className='w-full flex justify-center'>
                    <DemoButton/>
                </div>
            </div>
        </div>
    </section>
);

export default HowItWorksPageTemplate;