import React from 'react';

import { PageHeader, Icon } from '../components';

import '../styles/policies.css';

const PolicyCard = ({
    name,
    icon,
    pdf
}, i) => (
    <a key={ i } href={ pdf } aria-label={ `Download link for policy '${name}'` } target='_blank' rel='noreferrer'>
        <div className='policy-card'>
            <Icon icon={ icon } className='flex flex-col justify-center mr-4 lg:mr-8' iconClassName='text-5xl text-secondary'/>
            <div className='flex flex-col justify-center'>
                <div className='text-xl text-primary'>{ name }</div>
            </div>
        </div>
    </a>
);

const PoliciesPageTemplate = ({
    headerImage,
    headerTitle,
    heroTitle,
    heroSubtitle,
    policies
}) => (
    <div className='w-full pb-4 lg:pb-40'>
        <PageHeader
            text={ headerTitle }
            image={ headerImage }
        />
        <div className='w-full px-4 lg:px-0 lg:w-3/5 mx-auto my-4 lg:my-8 text-center text-primary text-3xl lg:text-5xl font-light'>{ heroTitle }</div>
        <div className='w-full px-4 lg:px-0 lg:w-3/5 mx-auto mb-8 lg:mb-16 text-center text-primary text-2xl font-light'>{ heroSubtitle }</div>
        <div className='w-full px-4 lx:px-0 lg:w-4/5 3xl:w-3/5 mx-auto grid md:grid-cols-2 xl:grid-cols-3 gap-4 lg:gap-8'>
            { policies.map(PolicyCard) }
        </div>
    </div>
);

export default PoliciesPageTemplate;